import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

import ContentWrapper from 'components/ContentWrapper';
import TopSection from 'components/TopSection';
import Img from 'gatsby-image';
import JoinUsButton from 'components/JoinUsButton';
import OurOffice from 'components/OurOffice';
import Box from 'components/ui/Box';

import { useToggle, useScrollToAdditionalContent, useWindowSize } from 'hooks';

import {
  Wrapper,
  SectionWithImage,
  TextWrapper,
  ImageWrapper,
  Statistics,
  Subtitle,
  List,
  JoinUsWrapper,
  AboutUsReadMoreButton,
} from './styled';

const getImageData = (images: any, imageName: string) =>
  images.filter(({ title }: { title: string }) => title.includes(imageName));

const BiographyIntro = () => {
  const { hash } = useLocation();

  const windowSize = useWindowSize();
  const width = windowSize?.width ?? 0;

  const biographyIntroContent = useBiographyIntroContent();

  const {
    on: isAdditionalContentVisible,
    toggle: toggleAdditionalContent,
    setOff: hideAdditionalContent,
  } = useToggle();

  const additionalContentRef = useScrollToAdditionalContent(
    isAdditionalContentVisible,
    250,
  );

  // Fix autoresizing Swiper problem
  React.useEffect(() => hideAdditionalContent, [hash, hideAdditionalContent]);

  return (
    <ContentWrapper>
      <TopSection
        title={biographyIntroContent.titleBio}
        subtitle={biographyIntroContent.subtitleBio}
        description={biographyIntroContent.descriptionBio}
      />
      <AboutUsReadMoreButton
        isAdditionalContentVisible={isAdditionalContentVisible}
        onClick={toggleAdditionalContent}>
        {biographyIntroContent.readMore}
      </AboutUsReadMoreButton>
      {isAdditionalContentVisible && (
        <Wrapper ref={additionalContentRef}>
          <SectionWithImage>
            <ImageWrapper>
              <Img
                imgStyle={{
                  maxWidth: width > 992 ? '472px' : 'none',
                  maxHeight: width > 992 ? '367px' : 'none',
                }}
                fluid={{
                  ...getImageData(
                    biographyIntroContent.images,
                    'biography1',
                  )?.[0]?.localFile?.childImageSharp.fluid,
                  aspectRatio: 1.28,
                }}
              />
            </ImageWrapper>
            <TextWrapper>
              <h2>
                <strong>{biographyIntroContent.sectionTitle1}</strong>
              </h2>
              <h4>{biographyIntroContent.sectionSubtitle1}</h4>
              <JoinUsWrapper>
                <JoinUsButton />
              </JoinUsWrapper>
            </TextWrapper>
          </SectionWithImage>
          <Statistics>
            <Box
              title={biographyIntroContent.statBoxTitle3}
              description={biographyIntroContent.statBoxDescription3}
            />
            <Box
              title={biographyIntroContent.statBoxTitle1}
              description={biographyIntroContent.statBoxDescription1}
            />
            <Box
              title={biographyIntroContent.statBoxTitle2}
              description={biographyIntroContent.statBoxDescription2}
            />
          </Statistics>
          <SectionWithImage reversed>
            <ImageWrapper>
              <Img
                imgStyle={{
                  maxWidth: width > 992 ? '472px' : 'none',
                  maxHeight: width > 992 ? '367px' : 'none',
                }}
                fluid={{
                  ...getImageData(
                    biographyIntroContent.images,
                    'biography2',
                  )?.[0]?.localFile?.childImageSharp?.fluid,
                  aspectRatio: 1.28,
                }}
              />
            </ImageWrapper>
            <TextWrapper>
              <Subtitle>{biographyIntroContent.sectionSubtitle}</Subtitle>
              <List>
                <li>{biographyIntroContent.offer1}</li>
                <li>{biographyIntroContent.offer2}</li>
                <li>{biographyIntroContent.offer3}</li>
                <li>{biographyIntroContent.offer4}</li>
                <li>{biographyIntroContent.offer5}</li>
                <li>{biographyIntroContent.offer6}</li>
              </List>
              {width > 900 && (
                <JoinUsWrapper>
                  <JoinUsButton />
                </JoinUsWrapper>
              )}
            </TextWrapper>
          </SectionWithImage>

          <OurOffice />
        </Wrapper>
      )}
    </ContentWrapper>
  );
};

export default BiographyIntro;

function useBiographyIntroContent() {
  const biographyIntroContent = useStaticQuery(
    graphql`
      query {
        aboutUs: wpPage(title: { in: "About Us" }) {
          pageContent {
            titleBio
            subtitleBio
            descriptionBio
          }
        }

        common: wpPage(title: { in: "Common" }) {
          pageContent {
            readMore
          }
        }

        biography: wpPage(title: { in: "Biography" }) {
          pageContent {
            sectionSubtitle1
            sectionSubtitle
            sectionTitle1
            statBoxDescription1
            statBoxDescription2
            statBoxDescription3
            statBoxTitle1
            statBoxTitle2
            statBoxTitle3
            offer1
            offer2
            offer3
            offer4
            offer5
            offer6
          }
        }

        images: allWpMediaItem(filter: { title: { regex: "/biography/" } }) {
          nodes {
            title
            localFile {
              name
              childImageSharp {
                fluid(maxWidth: 472, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `,
  );

  const {
    titleBio,
    subtitleBio,
    descriptionBio,
  } = biographyIntroContent.aboutUs.pageContent;

  const { readMore } = biographyIntroContent.common.pageContent;

  const {
    sectionSubtitle1,
    sectionSubtitle,
    sectionTitle1,
    statBoxDescription1,
    statBoxDescription2,
    statBoxDescription3,
    statBoxTitle1,
    statBoxTitle2,
    statBoxTitle3,
    offer1,
    offer2,
    offer3,
    offer4,
    offer5,
    offer6,
  } = biographyIntroContent.biography.pageContent;

  const images = biographyIntroContent.images.nodes;

  return {
    titleBio,
    subtitleBio,
    descriptionBio,
    readMore,
    sectionSubtitle1,
    sectionSubtitle,
    sectionTitle1,
    statBoxDescription1,
    statBoxDescription2,
    statBoxDescription3,
    statBoxTitle1,
    statBoxTitle2,
    statBoxTitle3,
    offer1,
    offer2,
    offer3,
    offer4,
    offer5,
    offer6,
    images,
  };
}
