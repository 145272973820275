import React, { ReactNode } from 'react';

import { Wrapper } from './styled';

interface Props {
  children: ReactNode;
}

const ContentWrapper = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};

export default ContentWrapper;
