import React from 'react';

import { Wrapper, Title, Description } from './styled';

interface Props {
  title?: string;
  description?: string;
}

const Box = ({ title, description }: Props) => {
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
    </Wrapper>
  );
};

export default Box;
