import React, { useState, useRef, useEffect } from 'react';
import styled from 'styles/styled';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { useOuterClick, useToggle, useWindowSize } from 'hooks';
import CrossIcon from 'images/icons/cross.svg';

import Portal from 'components/Portal';

import {
  GalleryWrapper,
  ModalWrapper,
  Modal,
  IconClose,
  ImageClickMask,
  OurOfficeTitle,
  OurOfficeParagraph,
  Gallery,
  GalleryItem,
  ArrowNext,
  ArrowPrev,
} from './styled';

const getContainedSize = (img: HTMLImageElement) => {
  const ratio = img.naturalWidth / img.naturalHeight || 0;
  let width = img.height * ratio;
  let { height } = img;
  if (width > img.width) {
    width = img.width;
    height = img.width / ratio;
  }
  return { width: Math.max(width, img.width), height };
};

type Dimensions = {
  width: number;
  height: number;
};

const OurOfficeWrapper = styled.div`
  margin-top: 48px;

  @media ${({ theme }) => theme.devices.medium} {
    margin-top: 160px;
  }
`;

const OurOffice = () => {
  const windowSize = useWindowSize();
  const imageMaskRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<any>();
  const arrowPrev = useRef<any>();
  const arrowNext = useRef<any>();
  const [imageDimensions, setImageDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });
  const [coverIndex, setCoverIndex] = useState(0);
  const { on: isModalOpen, setOn: openModal, setOff: closeModal } = useToggle();

  useOuterClick(imageMaskRef, (e: any) => {
    if (e.target === arrowPrev.current || e.target === arrowNext.current)
      return null;
    return closeModal();
  });

  const {
    wpPage: {
      pageContent: { subtitleOurOffice, descriptionOurOffice },
    },
    allWpMediaItem: { nodes: images },
  } = useStaticQuery(
    graphql`
      query {
        wpPage(title: { in: "About Us" }) {
          pageContent {
            subtitleOurOffice
            descriptionOurOffice
          }
        }
        allWpMediaItem(filter: { title: { regex: "/our-office/" } }) {
          nodes {
            localFile {
              id
              childImageSharp {
                fluid(quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `,
  );

  useEffect(() => {
    if (!isModalOpen) return;
    const dimensions = getContainedSize(imageRef?.current?.imageRef.current);
    setImageDimensions({ width: dimensions.width, height: dimensions.height });
  }, [isModalOpen, coverIndex]);

  const handleCoverChange = (index: number) => {
    setCoverIndex(index);
    openModal();
  };
  return (
    <OurOfficeWrapper>
      <OurOfficeTitle>{subtitleOurOffice}</OurOfficeTitle>
      <OurOfficeParagraph>{descriptionOurOffice}</OurOfficeParagraph>

      <GalleryWrapper>
        <Gallery>
          {(images as Array<any>).map(
            (
              {
                localFile: {
                  id,
                  childImageSharp: { fluid },
                },
              },
              index,
            ) => (
              <GalleryItem
                key={id}
                onClick={() =>
                  (windowSize?.width ?? 0) >= 450 && handleCoverChange(index)
                }>
                <Img fluid={{ ...fluid, aspectRatio: 1 }} />
              </GalleryItem>
            ),
          )}
        </Gallery>
        {isModalOpen && (
          <Portal>
            <ModalWrapper>
              <IconClose aria-label="close image preview" onClick={closeModal}>
                <img src={CrossIcon} alt="close" />
              </IconClose>
              {coverIndex !== 0 && (
                <ArrowPrev
                  ref={arrowPrev}
                  className="swiper-button-prev"
                  onClick={() => setCoverIndex(coverIndex - 1)}
                />
              )}
              {coverIndex !== images.length - 1 && (
                <ArrowNext
                  ref={arrowNext}
                  className="swiper-button-next"
                  onClick={() => setCoverIndex(coverIndex + 1)}
                />
              )}
              <Modal>
                <Img
                  fluid={{
                    ...images[coverIndex].localFile.childImageSharp.fluid,
                  }}
                  imgStyle={{ objectFit: 'contain', height: '100vh' }}
                  ref={imageRef}
                />
                <ImageClickMask
                  ref={imageMaskRef}
                  width={imageDimensions.width}
                  height={imageDimensions.height}
                />
              </Modal>
            </ModalWrapper>
          </Portal>
        )}
      </GalleryWrapper>
    </OurOfficeWrapper>
  );
};

export default OurOffice;
