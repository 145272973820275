import React from 'react';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';

import ContentWrapper from 'components/ContentWrapper';
import TopSection from 'components/TopSection';
import ReadMoreButton from 'components/ui/ReadMoreButton';
import { useToggle, useScrollToAdditionalContent } from 'hooks';

import {
  ExpertiseReadMoreWrapper,
  WeAreExpertsInTitle,
  ExpertiseItemTitle,
  FirstExpertiseItemTitle,
  ExpertiseItemDescriptionParagraph,
  Wrapper,
  OurExpertiseHeader,
} from './styled';

const OurExpertise = () => {
  const { hash } = useLocation();

  const ourExpertiseContent = useOurExpertiseContent();

  const {
    on: isAdditionalContentVisible,
    toggle: toggleAdditionalContent,
    setOff: hideAdditionalContent,
  } = useToggle();

  const additionalContentRef = useScrollToAdditionalContent(
    isAdditionalContentVisible,
    250,
  );

  // Fix autoresizing Swiper problem
  React.useEffect(() => hideAdditionalContent, [hash, hideAdditionalContent]);

  return (
    <Wrapper>
      <OurExpertiseHeader>{ourExpertiseContent.pageTitle}</OurExpertiseHeader>
      <ContentWrapper>
        <TopSection
          title={ourExpertiseContent.title}
          subtitle={ourExpertiseContent.subtitle}
          description={ourExpertiseContent.description}
        />
      </ContentWrapper>

      <ReadMoreButton onClick={toggleAdditionalContent}>
        {ourExpertiseContent.readMore}
      </ReadMoreButton>

      {isAdditionalContentVisible && (
        <ExpertiseReadMoreWrapper ref={additionalContentRef}>
          <WeAreExpertsInTitle>
            {ourExpertiseContent.weAreExpertsIn}
          </WeAreExpertsInTitle>

          <FirstExpertiseItemTitle>
            {ourExpertiseContent.firstExpertise}
          </FirstExpertiseItemTitle>
          <ExpertiseItemDescriptionParagraph>
            {ourExpertiseContent.firstExpertiseDescription}
          </ExpertiseItemDescriptionParagraph>

          <ExpertiseItemTitle>
            {ourExpertiseContent.secondExpertise}
          </ExpertiseItemTitle>
          <ExpertiseItemDescriptionParagraph>
            {ourExpertiseContent.secondExpertiseDescription}
          </ExpertiseItemDescriptionParagraph>
        </ExpertiseReadMoreWrapper>
      )}
    </Wrapper>
  );
};

export default OurExpertise;

function useOurExpertiseContent() {
  const {
    ourExpertise: {
      pageContent: {
        pageTitle,
        description,
        subtitle,
        title,
        weAreExpertsIn,
        firstExpertise,
        firstExpertiseDescription,
        secondExpertise,
        secondExpertiseDescription,
      },
    },
    common: {
      pageContent: { readMore },
    },
  } = useStaticQuery(
    graphql`
      query {
        ourExpertise: wpPage(title: { in: "Our expertise" }) {
          pageContent {
            pageTitle
            description
            subtitle
            title
            weAreExpertsIn
            firstExpertise
            firstExpertiseDescription
            secondExpertise
            secondExpertiseDescription
          }
        }

        common: wpPage(title: { in: "Common" }) {
          pageContent {
            readMore
          }
        }
      }
    `,
  );

  return {
    pageTitle,
    description,
    subtitle,
    title,
    readMore,
    weAreExpertsIn,
    firstExpertise,
    firstExpertiseDescription,
    secondExpertise,
    secondExpertiseDescription,
  };
}
