import styled from 'styles/styled';
import { gradientTextStyles } from 'components/ui/Heading';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    ${gradientTextStyles};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSize.title};
    margin-bottom: 1px;

    @media ${({ theme }) => theme.devices.medium} {
      margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 2}px;
    }
  }

  h3 {
    text-align: center;
    max-width: 180px;
    @media ${({ theme }) => theme.devices.medium} {
      max-width: 100%;
    }
  }

  @media ${({ theme }) => theme.devices.medium} {
    h3 {
      align-self: center;
      ::first-letter {
        text-transform: capitalize;
      }
    }

    h2 {
      font-size: ${({ theme }) => theme.fontSize.title};
    }
  }
`;

export const Title = styled.h2`
  letter-spacing: -1px;
`;

export const Description = styled.h3`
  line-height: normal;
`;
