import styled from 'styles/styled';

import ReadMoreButton from 'components/ui/ReadMoreButton';
import { Heading } from 'components/ui/Heading';
import { WhiteParagraph } from 'components/ui/Paragraph/Paragraph';

import { useToggle } from 'hooks';

export const DepartmentsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 0 !important;

  @media ${({ theme }) => theme.devices.medium} {
    margin-top: 320px !important;
  }
`;

export const OurDepartmentsReadMoreButton = styled(ReadMoreButton)<{
  isAdditionalContentVisible: ReturnType<typeof useToggle>['on'];
}>`
  margin-bottom: 110px;
  margin-top: 90px;

  @media ${({ theme }) => theme.devices.medium} {
    margin-bottom: 0;
    margin-top: 58px;
  }
`;

export const SectionTitle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: 0.23px;
  margin-bottom: 0;
  text-align: left;
  line-height: 1.23;
  font-size: ${({ theme }) => theme.fontSize.titleMobile};

  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.title};
    text-align: center;
    margin-bottom: 15px;
  }
`;

export const ItemsWrapper = styled.div`
  padding-top: 35px;

  @media ${({ theme }) => theme.devices.medium} {
    padding-top: 100px;
  }
`;

export const JoinUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 68px;
  padding-bottom: 65px;

  @media ${({ theme }) => theme.devices.medium} {
    margin-top: 140px;
  }
`;

export const SectionDesc = styled(WhiteParagraph)`
  text-align: center;
  display: none;

  @media ${({ theme }) => theme.devices.medium} {
    display: block;
  }
`;

export const JoinUsTitle = styled.h3`
  text-align: left;
  font-size: ${({ theme }) => theme.fontSize.titleMobile};
  margin-bottom: 20px;
  line-height: normal;
  font-weight: bold;

  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.title};
    text-align: center;
    margin-bottom: 37px;
  }
`;

export const OurDepartmentsHeading = styled(Heading)`
  > span {
    margin-left: 0;
    font-size: 38px;
  }

  margin-top: 40px;

  @media (min-width: 378px) {
    margin-top: 80px;
  }

  @media (min-width: 475px) {
    > span {
      font-size: 48px;
    }
  }

  @media ${({ theme }) => theme.devices.medium} {
    margin-top: 40px;
    > span {
      font-size: 110px;
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    > span {
      font-size: 130px;
    }
  }
`;

export const DepartmentItemWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 12px;
    @media ${({ theme }) => theme.devices.medium} {
      margin-top: 40px;
    }
  }
`;

export const DepartmentTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-top: 20px;
  border-bottom: 2px solid ${({ theme }) => theme.palette.secondary};
  cursor: pointer;

  @media ${({ theme }) => theme.devices.medium} {
    padding-bottom: 20px;
  }
`;

export const DepartmentTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.contentText};
  line-height: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  @media ${({ theme }) => theme.devices.medium} {
    line-height: normal;
    font-size: ${({ theme }) => theme.fontSize.labelBig};
  }
`;

export const DepartmentArrow = styled.div<{ showDesc?: boolean }>`
  margin-top: auto;
  margin-right: 3px;
  margin-bottom: ${({ showDesc }) => (showDesc ? '4px' : '8px')};
  width: 9.6px;
  height: 9.6px;
  transition: transform ease 0.3s;
  transform: ${({ showDesc }) =>
    showDesc ? 'rotate(45deg)' : 'rotate(-135deg)'};
  border-top: 1.6px solid ${({ theme }) => theme.palette.light};
  border-left: 1.6px solid ${({ theme }) => theme.palette.light};
  @media ${({ theme }) => theme.devices.medium} {
    margin-right: 25px;
    width: 12px;
    height: 12px;
    border-top-width: 2px;
    border-left-width: 2px;
  }
`;

export const DepartmentDescWrapper = styled.div<{ showDesc?: boolean }>`
  max-height: ${({ showDesc }) => (showDesc ? '500px' : 0)};
  overflow: hidden;
  transition: max-height, padding ease 0.3s;
  font-size: ${({ theme }) => theme.fontSize.base};
  padding-top: ${({ showDesc }) => (showDesc ? '15px' : 0)};

  line-height: 1.5;
  letter-spacing: 0.14px;

  &&& p {
    color: ${({ theme }) => theme.palette.light};
  }

  @media ${({ theme }) => theme.devices.medium} {
    &&& p {
      color: ${({ theme }) => theme.palette.lightDark};
    }
    padding-top: ${({ showDesc }) => (showDesc ? '20px' : 0)};
  }
`;

export const TopSectionWrapper = styled.div`
  article {
    padding: 0;

    h2 {
      margin-left: 0;
    }
  }

  @media ${({ theme }) => theme.devices.medium} {
    article {
      padding: 0 0 0 80px;
    }
  }
  @media ${({ theme }) => theme.devices.large} {
    margin: 20px 180px 0;
  }
`;

export const OuterWrapper = styled.div`
  display: block;
  > div {
    > section {
      max-width: 928px;
      margin: 0 auto;
    }
  }
`;
