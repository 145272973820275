import styled from 'styles/styled';

import Title from 'components/ui/Title';
import Paragraph from 'components/ui/Paragraph';
import { Heading } from 'components/ui/Heading';

export const WeAreExpertsInTitle = styled(Title)`
  margin-left: 0;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: left;

  @media ${({ theme }) => theme.devices.medium} {
    text-align: center;
  }
`;

export const ExpertiseItemTitle = styled(Title)`
  margin-left: 0;
  margin-top: ${({ theme }) => theme.dimensions.spacingBase10 * 5}px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: left;
  letter-spacing: 0.23px;
  @media ${({ theme }) => theme.devices.medium} {
    margin-top: ${({ theme }) => theme.dimensions.spacingBase10 * 7.5}px;
  }
`;

export const FirstExpertiseItemTitle = styled(ExpertiseItemTitle)`
  margin-top: ${({ theme }) => theme.dimensions.spacingBase10 * 3}px;

  @media ${({ theme }) => theme.devices.medium} {
    margin-top: ${({ theme }) => theme.dimensions.spacingBase10 * 7}px;
  }
`;

export const ExpertiseItemDescriptionParagraph = styled(Paragraph)`
  line-height: 22.5px;
  color: ${({ theme }) => theme.palette.light};
  margin-top: ${({ theme }) => theme.dimensions.spacingBase10 * 1.5}px;
`;

export const ExpertiseReadMoreWrapper = styled.div`
  margin-top: ${({ theme }) => theme.dimensions.spacingBase10 * 11}px;

  @media ${({ theme }) => theme.devices.medium} {
    margin-top: ${({ theme }) => theme.dimensions.spacingBase10 * 31}px;
    margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 1.5}px;
  }
`;

export const Wrapper = styled.div`
  margin-right: auto;
  padding-bottom: 50px;

  @media ${({ theme }) => theme.devices.medium} {
    padding-bottom: 70px;
  }
`;

export const OurExpertiseHeader = styled(Heading)`
  margin-top: 40px;

  @media (min-width: 378px) {
    margin-top: 80px;
  }

  @media ${({ theme }) => theme.devices.medium} {
    margin-top: 55px;
  }
`;
