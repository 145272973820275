import styled from 'styles/styled';

import ReadMoreButton from 'components/ui/ReadMoreButton';
import { useToggle } from 'hooks';

export const AboutUsReadMoreButton = styled(ReadMoreButton)<{
  isAdditionalContentVisible: ReturnType<typeof useToggle>['on'];
}>`
  margin-bottom: 0px;
`;

export const Wrapper = styled.div`
  margin-top: 85px;

  > div {
    padding: 0;
  }

  @media ${({ theme }) => theme.devices.medium} {
    margin-top: 320px;
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    padding: 0;
  }
`;

export const ImageWrapper = styled.div`
  @media ${({ theme }) => theme.devices.large} {
    max-width: 472px;
  }
`;

export const SectionWithImage = styled.section<{ reversed?: boolean }>`
  display: grid;
  margin-bottom: 42px;
  max-width: calc(472px + 50px + 405px);
  grid-gap: 26px;
  margin-left: auto;
  margin-right: auto;

  ${ImageWrapper} {
    clip-path: polygon(
      0% 25%,
      25% 0%,
      100% 0%,
      100% 10%,
      100% 80%,
      75% 100%,
      0 100%,
      0% 75%
    );
  }

  @media ${({ theme }) => theme.devices.large} {
    grid-gap: 50px;
    grid-template-columns: ${({ reversed }) =>
      reversed ? '1fr 472px' : '472px 1fr'};
    margin-bottom: 60px;

    ${ImageWrapper} {
      grid-column: ${({ reversed }) => reversed && '2'};
      grid-row: 1;
    }
  }
`;

export const TextWrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 405px;
  margin-left: auto;
  margin-right: auto;

  h2 {
    align-self: flex-start;
    margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10}px;
    font-weight: bold;
  }

  h4 {
    font-size: ${({ theme }) => theme.fontSize.baseMobile};
    line-height: 1.5;
    letter-spacing: 0.14px;
    color: ${({ theme }) => theme.palette.light};

    @media ${({ theme }) => theme.devices.medium} {
      font-size: ${({ theme }) => theme.fontSize.base};
    }
  }

  button {
    align-self: flex-start;
  }

  @media ${({ theme }) => theme.devices.medium} {
    h2 {
      margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 2}px;
    }

    h3 {
      margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 2}px;
    }
  }
`;

export const Statistics = styled.section`
  position: relative;
  display: grid;
  grid-gap: 36px;
  padding: 0 40px;
  margin-bottom: 52px;
  max-width: calc(472px + 50px + 405px);
  margin-left: auto;
  margin-right: auto;

  article {
    :nth-child(2) {
      h2 {
        letter-spacing: 0.44px;
      }
    }

    :nth-child(1) {
      h2 {
        letter-spacing: -5px;
        padding-right: 3px;
      }
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    grid-template-columns: 1.1fr 1fr 1fr;
    padding: 0;
    margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 16}px;
  }
`;

export const Subtitle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: ${({ theme }) => theme.dimensions.spacingBase10}px 0;

  @media ${({ theme }) => theme.devices.medium} {
    margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 2}px;
  }
`;

export const List = styled.ul`
  li {
    position: relative;
    color: ${({ theme }) => theme.palette.light};
    font-size: ${({ theme }) => theme.fontSize.label};
    letter-spacing: 0.13px;
    line-height: 18px;
    padding-left: ${({ theme }) => theme.dimensions.spacingBase10 * 3}px;
    font-size: ${({ theme }) => theme.fontSize.baseMobile};

    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 1.5}px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 6px;
      left: 14.5px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: ${({ theme }) => theme.gradient.primary};
    }

    @media ${({ theme }) => theme.devices.medium} {
      font-size: ${({ theme }) => theme.fontSize.base};
      letter-spacing: 0.23px;
      line-height: 22px;

      &:before {
        top: 7.5px;
      }
    }
  }
`;

export const JoinUsWrapper = styled.div`
  width: 124px;
  margin: 20px auto 0 auto;
  @media ${({ theme }) => theme.devices.large} {
    display: block;
    margin: 25px 0 0;
    width: 170px;
  }
`;
