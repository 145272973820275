import styled from 'styles/styled';

import Title from 'components/ui/Title';
import { WhiteParagraph } from 'components/ui/Paragraph/Paragraph';

export const GalleryWrapper = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.devices.xlarge} {
    margin-bottom: 80px;
  }
`;

export const ModalWrapper = styled.div`
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
`;

export const Modal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 40px 50px;
  overflow: hidden;
`;

export const IconClose = styled.button`
  background: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  outline: 0;
  cursor: pointer;
  transition: ${({ theme }) => theme.ui.transition('opacity')};

  &:hover {
    opacity: ${({ theme }) => theme.ui.opacity()};
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

export const ImageClickMask = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  background-color: transparent;
  z-index: 1;
`;

export const OurOfficeTitle = styled(Title)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-left: 0;
  margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 1.5}px;
  letter-spacing: 0.23px;

  @media ${({ theme }) => theme.devices.medium} {
    text-align: center;
  }
`;

export const OurOfficeParagraph = styled(WhiteParagraph)`
  max-width: 680px;
  margin: 5px auto 30px auto;
  letter-spacing: 0.14px;
  line-height: 1.47;
  @media ${({ theme }) => theme.devices.medium} {
    text-align: center;
  }
`;

export const Gallery = styled.ul`
  list-style-type: none;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(1, 1fr);

  @media ${({ theme }) => theme.devices.medium} {
    grid-gap: 20px;
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const GalleryItem = styled.li`
  cursor: pointer;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  clip-path: polygon(
    0% 25%,
    25% 0%,
    100% 0%,
    100% 10%,
    100% 80%,
    75% 100%,
    0 100%,
    0% 75%
  );

  @media ${({ theme }) => theme.devices.xsmall} {
    max-width: 300px;
    max-height: 300px;
  }

  @media ${({ theme }) => theme.devices.medium} {
    max-width: 200px;
    max-height: 200px;
  }
`;

export const ArrowPrev = styled.div`
  top: 50%;
  left: 30px;
`;

export const ArrowNext = styled.div`
  top: 50%;
  right: 30px;
`;
