import styled, { keyframes } from 'styles/styled';

const rotate = keyframes`
  100% {
    -webkit-transform: rotate(360deg);
  }
`;

interface Props {
  center: boolean;
  size: number;
  slim: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const Spinner = styled.div<Props>`
  height: 0;
  width: 0;
  padding: ${({ size }) => `${size}px`};
  border-width: ${({ slim }) => (slim ? 4 : 8)}px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.primary};
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${rotate} 1s infinite linear;
  margin: ${({ center }) => (center ? '0 auto' : '0')};

  @media ${({ theme }) => theme.devices.medium} {
    border-width: ${({ slim }) => (slim ? 6 : 12)}px;
  }
`;
