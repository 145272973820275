import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import ContentWrapper from 'components/ContentWrapper';
import TopSection from 'components/TopSection';
import { Heading } from 'components/ui/Heading';

const Hello = () => {
  const { pageTitle, description, subtitle, title } = useHelloContent();

  return (
    <>
      <Heading>{pageTitle}</Heading>
      <ContentWrapper>
        <TopSection
          title={title}
          subtitle={subtitle}
          description={description}
        />
      </ContentWrapper>
    </>
  );
};

export default Hello;

function useHelloContent() {
  const {
    wpPage: {
      pageContent: { pageTitle, description, subtitle, title },
    },
  } = useStaticQuery(
    graphql`
      query {
        wpPage(title: { in: "Hello" }) {
          pageContent {
            pageTitle
            description
            subtitle
            title
          }
        }
      }
    `,
  );

  return { pageTitle, description, subtitle, title };
}
