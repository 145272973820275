import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const modalRoot =
  typeof document !== 'undefined' ? document.getElementById('portal') : null;

const Portal = ({ children }: any) => {
  const elRef = useRef<any>(null);
  if (!elRef.current) {
    elRef.current =
      typeof document !== `undefined` ? document.createElement('div') : null;
  }

  useEffect(() => {
    modalRoot?.appendChild(elRef.current);
    return () => modalRoot?.removeChild(elRef.current);
  }, []);

  if (!elRef.current) {
    return null;
  }

  return createPortal(<div>{children}</div>, elRef.current);
};

export default Portal;
