import React, { useRef, useEffect, useState } from 'react';
import SwiperCore, { Navigation, HashNavigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLocation, navigate } from '@reach/router';
import 'swiper/swiper-bundle.min.css';

import { HASHES } from 'constants/common';

import Loader from 'components/ui/Loader';
import Hello from 'components/Hello';
import AboutUs from 'components/AboutUs';
import OurExpertise from 'components/OurExpertise';
import OurDepartments from 'components/OurDepartments';

import { LoaderWrapper } from './styled';

SwiperCore.use([Navigation, HashNavigation]);

const Slider = () => {
  const { hash } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const swiperRef = useRef<any>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
    if (!hash) {
      navigate(`/${HASHES.hello}`);
      return;
    }
    if (hash === HASHES.hello) {
      swiperRef.current.slideTo(0);
      return;
    }
    if (hash === HASHES.aboutUs) {
      swiperRef.current.slideTo(1);
      return;
    }
    if (hash === HASHES.ourExpertise) {
      swiperRef.current.slideTo(2);
      return;
    }
    if (hash === HASHES.ourDepartments) {
      swiperRef.current.slideTo(3);
    }
  }, [hash]);

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      simulateTouch={false}
      navigation
      // eslint-disable-next-line no-return-assign
      onSwiper={(swiper) => (swiperRef.current = swiper)}
      hashNavigation={{ watchState: true }}>
      {isLoading ? (
        <LoaderWrapper>
          <Loader center slim />
        </LoaderWrapper>
      ) : (
        <>
          <SwiperSlide data-hash="hello">
            <Hello />
          </SwiperSlide>
          <SwiperSlide data-hash="about-us">
            <AboutUs />
          </SwiperSlide>
          <SwiperSlide data-hash="our-expertise">
            <OurExpertise />
          </SwiperSlide>
          <SwiperSlide data-hash="our-departments">
            <OurDepartments />
          </SwiperSlide>
        </>
      )}
    </Swiper>
  );
};

export default Slider;
