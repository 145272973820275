import React from 'react';

import { Spinner } from './styled';

interface Props {
  size?: number;
  center?: boolean;
  slim?: boolean;
}

const Loader = ({ size = 65, center = false, slim = false }: Props) => {
  return <Spinner size={size} center={center} slim={slim} />;
};

export default Loader;
