import styled from 'styles/styled';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px auto ${({ theme }) => theme.dimensions.spacingBase10 * 2}px;
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthBig}px;

  > section {
    max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;
    margin: 0 auto;
  }

  @media ${({ theme }) => theme.devices.medium} {
    margin: 108px auto ${({ theme }) => theme.dimensions.spacingBase10 * 3}px;
  }

  @media ${({ theme }) => theme.devices.large} {
    > section {
      margin: 0 auto 0 162px;
    }
  }
`;
