import styled from 'styles/styled';
import { Button } from 'components/ui/Button';

// eslint-disable-next-line
const ReadMoreButton = styled(Button)`
  margin-top: 60px;
  font-size: 12px;
  letter-spacing: 1.33px;
  line-height: normal;
  margin: 44px auto;
  padding: 9px 38px;

  @media ${({ theme }) => theme.devices.medium} {
    font-size: 15px;
    letter-spacing: 1.67px;
    margin: 34px auto 0 auto;
    padding: 12px 47px;
  }
`;

export default ReadMoreButton;
