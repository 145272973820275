import React from 'react';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';

import ContentWrapper from 'components/ContentWrapper';
import TopSection from 'components/TopSection';
import JoinUsButton from 'components/JoinUsButton';
import { WhiteParagraph } from 'components/ui/Paragraph/Paragraph';
import { useToggle, useScrollToAdditionalContent } from 'hooks';
import {
  DepartmentsWrapper,
  SectionTitle,
  ItemsWrapper,
  JoinUsWrapper,
  JoinUsTitle,
  OurDepartmentsReadMoreButton,
  OurDepartmentsHeading,
  DepartmentItemWrapper,
  DepartmentTitleWrapper,
  DepartmentTitle,
  DepartmentDescWrapper,
  DepartmentArrow,
  TopSectionWrapper,
  OuterWrapper,
  SectionDesc,
} from './styled';

const OurDepartments = () => {
  const { hash } = useLocation();

  const {
    pageTitle,
    description,
    subtitle,
    title,
    readMore,
    departments,
    exploreOurCoreDepartments,
    exploreOurCoreDepartmentsDescription,
    joinUsTitle,
  } = useOurDepartmentsContent();

  const {
    on: isAdditionalContentVisible,
    toggle: toggleAdditionalContent,
    setOff: hideAdditionalContent,
  } = useToggle();

  const additionalContentRef = useScrollToAdditionalContent(
    isAdditionalContentVisible,
    400,
  );

  const [expandedDepartmentIndex, setExpandedDepartmentIndex] = React.useState<
    number | undefined
  >();

  // Fix autoresizing Swiper problem
  React.useEffect(() => hideAdditionalContent, [hash, hideAdditionalContent]);

  return (
    <>
      <OurDepartmentsHeading dangerouslySetInnerHTML={{ __html: pageTitle }} />
      <OuterWrapper>
        <ContentWrapper>
          <TopSectionWrapper>
            <TopSection
              title={title}
              subtitle={subtitle}
              description={description}
            />
          </TopSectionWrapper>
          <OurDepartmentsReadMoreButton
            isAdditionalContentVisible={isAdditionalContentVisible}
            onClick={toggleAdditionalContent}>
            {readMore}
          </OurDepartmentsReadMoreButton>
          {isAdditionalContentVisible && (
            <DepartmentsWrapper ref={additionalContentRef}>
              <SectionTitle>{exploreOurCoreDepartments}</SectionTitle>
              <SectionDesc>{exploreOurCoreDepartmentsDescription}</SectionDesc>
              <ItemsWrapper>
                {departments.map(({ title, description }, index) => (
                  <DepartmentItemWrapper key={title}>
                    <DepartmentTitleWrapper
                      onClick={() =>
                        setExpandedDepartmentIndex(
                          expandedDepartmentIndex === index ? undefined : index,
                        )
                      }>
                      <DepartmentTitle>{title}</DepartmentTitle>
                      <DepartmentArrow
                        showDesc={expandedDepartmentIndex === index}
                      />
                    </DepartmentTitleWrapper>
                    <DepartmentDescWrapper
                      showDesc={expandedDepartmentIndex === index}>
                      <WhiteParagraph>{description}</WhiteParagraph>
                    </DepartmentDescWrapper>
                  </DepartmentItemWrapper>
                ))}
              </ItemsWrapper>
              <JoinUsWrapper>
                <JoinUsTitle>{joinUsTitle}</JoinUsTitle>
                <JoinUsButton />
              </JoinUsWrapper>
            </DepartmentsWrapper>
          )}
        </ContentWrapper>
      </OuterWrapper>
    </>
  );
};

export default OurDepartments;

function useOurDepartmentsContent() {
  const {
    ourDepartments: {
      pageContent: {
        pageTitle,
        description,
        subtitle,
        title,
        exploreOurCoreDepartments,
        exploreOurCoreDepartmentsDescription,
        joinUsTitle,
        ...departments
      },
    },
    common: {
      pageContent: { readMore },
    },
  } = useStaticQuery(
    graphql`
      query {
        ourDepartments: wpPage(title: { in: "Our departments" }) {
          pageContent {
            pageTitle
            description
            subtitle
            title
            departmentNumber1
            departmentNumber1Description
            departmentNumber2
            departmentNumber2Description
            departmentNumber3
            departmentNumber3Description
            departmentNumber4
            departmentNumber4Description
            departmentNumber5
            departmentNumber5Description
            departmentNumber6
            departmentNumber6Description
            departmentNumber7
            departmentNumber7Description
            departmentNumber8
            departmentNumber8Description
            departmentNumber9
            departmentNumber9Description
            departmentNumber10
            departmentNumber10Description
            departmentNumber11
            departmentNumber11Description
            departmentNumber12
            departmentNumber12Description
            exploreOurCoreDepartments
            exploreOurCoreDepartmentsDescription
            joinUsTitle
          }
        }

        common: wpPage(title: { in: "Common" }) {
          pageContent {
            readMore
          }
        }
      }
    `,
  );

  return {
    pageTitle,
    description,
    subtitle,
    title,
    readMore,
    exploreOurCoreDepartments,
    exploreOurCoreDepartmentsDescription,
    joinUsTitle,
    departments: [
      {
        title: departments.departmentNumber1,
        description: departments.departmentNumber1Description,
      },
      {
        title: departments.departmentNumber2,
        description: departments.departmentNumber2Description,
      },
      {
        title: departments.departmentNumber3,
        description: departments.departmentNumber3Description,
      },
      {
        title: departments.departmentNumber4,
        description: departments.departmentNumber4Description,
      },
      {
        title: departments.departmentNumber5,
        description: departments.departmentNumber5Description,
      },
      {
        title: departments.departmentNumber6,
        description: departments.departmentNumber6Description,
      },
      {
        title: departments.departmentNumber7,
        description: departments.departmentNumber7Description,
      },
      {
        title: departments.departmentNumber8,
        description: departments.departmentNumber8Description,
      },
      {
        title: departments.departmentNumber9,
        description: departments.departmentNumber9Description,
      },
      {
        title: departments.departmentNumber10,
        description: departments.departmentNumber10Description,
      },
      {
        title: departments.departmentNumber11,
        description: departments.departmentNumber11Description,
      },
      {
        title: departments.departmentNumber12,
        description: departments.departmentNumber12Description,
      },
    ],
  };
}
