import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Heading } from 'components/ui/Heading';
import BiographyIntro from 'components/BiographyIntro';

const AboutUs = () => {
  const pageTitle = useAboutUsPageTitle();

  return (
    <>
      <Heading>{pageTitle}</Heading>
      <BiographyIntro />
    </>
  );
};

export default AboutUs;

function useAboutUsPageTitle(): string {
  const {
    wpPage: {
      pageContent: { pageTitle },
    },
  } = useStaticQuery(
    graphql`
      query {
        wpPage(title: { in: "About Us" }) {
          pageContent {
            pageTitle
          }
        }
      }
    `,
  );

  return pageTitle;
}
