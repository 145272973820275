import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Slider from 'components/ui/Slider';

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Welcome! - CloudBest"
        description="CloudBest manages international projects providing support within areas such as software development, content creation, customer support, marketing, business development for clients around the world."
      />
      <Slider />
    </Layout>
  );
};

export default IndexPage;
